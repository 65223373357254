<template>
    <div>
        <pre>{{ preview }}</pre>
    </div>
</template>

<script>
import CmsBaseComponent from '@modules/page-builder/components/CmsBaseComponent/CmsBaseComponent';

export default {
    name: 'CmsAppCartScreenV2',

    extends: CmsBaseComponent,

    props: {
        // eslint-disable-next-line vue/no-unused-properties
        slotAddToCartSuccessItems: {
            type: String,
            default: JSON.stringify(''),
        },

        slotEmptyCartItems: {
            type: String,
            default: JSON.stringify(''),
        },
    },

    data() {
        return {
            // eslint-disable-next-line vue/no-unused-properties
            defaultConfig: {
                slots: {
                    addToCartSuccessItems: '',
                    emptyCartItems: '',
                },
            },

            // eslint-disable-next-line vue/no-unused-properties
            options: {
                marginTop: false,
                staticClass: false,
            },
        };
    },

    computed: {
        preview() {
            return {
                ...this.propsToBind,
                slotAddToCartSuccessItems: this.slotAddToCartSuccessItems
                    ? JSON.parse(this.slotAddToCartSuccessItems)
                    : '',

                slotEmptyCartItems: this.slotEmptyCartItems
                    ? JSON.parse(this.slotEmptyCartItems)
                    : '',
            };
        },
    },
};
</script>
