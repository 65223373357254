<template>
    <div>
        <pre>{{ preview }}</pre>
    </div>
</template>

<script>
import CmsBaseComponent from '@modules/page-builder/components/CmsBaseComponent/CmsBaseComponent';

export default {
    name: 'CmsAppEnableNotificationsBannerV2',

    extends: CmsBaseComponent,

    props: {
        // eslint-disable-next-line vue/no-unused-properties
        disabled: {
            type: Boolean,
            default: false,
        },
    },

    data() {
        return {
            // eslint-disable-next-line vue/no-unused-properties
            defaultConfig: {
                props: {
                    disabled: false,
                },
            },

            // eslint-disable-next-line vue/no-unused-properties
            options: {
                marginTop: false,
                staticClass: false,
            },
        };
    },

    computed: {
        preview() {
            return {
                ...this.propsToBind,
            };
        },
    },
};
</script>
