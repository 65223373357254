<template>
    <div class="fixed-action">
        <BannerInfo :title="title" :description="description">
            <template #icon>
                <Icon :icon="PromoCode" />
            </template>

            <template #button>
                <ButtonText
                    :variant="BUTTON_TEXT_VARIANT"
                    :no-padding="true"
                    class="button"
                    @click="isModalOpened = true"
                >
                    {{ buttonText }}
                </ButtonText>
            </template>
        </BannerInfo>
        <FixedActionModal
            v-if="isModalOpened"
            :modal-title="modalTitle"
            :title="modalTextTitle"
            :description="modalTextDescription"
            @close="isModalOpened = false"
        />
    </div>
</template>

<script>
import { BannerInfo } from '@eobuwie-ui/components/BannerInfo/v1';
import {
    ButtonText,
    BUTTON_TEXT_VARIANTS,
} from '@eobuwie-ui/components/ButtonText/v1';
import { Icon } from '@eobuwie-ui/components/Icon/v1';

import { PromoCode } from '@eobuwie-ui/icons/v2/other';
import FixedActionModal from '@molecules/FixedAction/FixedActionModal';

export default {
    name: 'FixedAction',

    components: {
        FixedActionModal,
        Icon,
        BannerInfo,
        ButtonText,
    },

    props: {
        title: {
            type: String,
            default: '',
        },

        description: {
            type: String,
            required: true,
        },

        buttonText: {
            type: String,
            default: '',
        },

        modalTitle: {
            type: String,
            required: true,
        },

        modalTextTitle: {
            type: String,
            default: '',
        },

        modalTextDescription: {
            type: String,
            required: true,
        },
    },

    data() {
        return {
            isModalOpened: false,
        };
    },

    beforeCreate() {
        this.PromoCode = PromoCode;
        this.BUTTON_TEXT_VARIANT = BUTTON_TEXT_VARIANTS.PRIMARY;
    },
};
</script>

<style lang="scss" scoped>
.fixed-action {
    .button {
        @apply h-ui-11 w-ui-auto;

        &:deep(.label) {
            @apply font-ui-body-s-bold;
        }
    }
}
</style>
