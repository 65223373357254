<template>
    <DialogWrapper :show="true" class="fixed-action-modal">
        <DialogCentered
            :show="true"
            :label="modalTitle"
            @close="$emit('close')"
            @button-trailing-click="$emit('close')"
        >
            <template #icon-trailing>
                <Icon :icon="Close" />
            </template>

            <div class="text">
                <BodyText :variant="BODY_TEXT_BOLD" :size="BODY_TEXT_SIZE">
                    {{ title }}
                </BodyText>
                <BodyText :size="BODY_TEXT_SIZE">
                    <div v-html="description" />
                </BodyText>
            </div>
        </DialogCentered>
    </DialogWrapper>
</template>

<script>
import DialogWrapper from '@molecules/DialogWrapper/DialogWrapper';

import { DialogCentered } from '@eobuwie-ui/components/DialogCentered/v1';
import { Icon } from '@eobuwie-ui/components/Icon/v1';
import {
    BodyText,
    BODY_TEXT_VARIANTS,
    BODY_TEXT_SIZES,
} from '@eobuwie-ui/components/BodyText/v1';

import { Close } from '@eobuwie-ui/icons/v2/navigation';

export default {
    components: { DialogWrapper, DialogCentered, Icon, BodyText },

    props: {
        modalTitle: {
            type: String,
            required: true,
        },

        description: {
            type: String,
            required: true,
        },

        title: {
            type: String,
            default: '',
        },
    },

    beforeCreate() {
        this.Close = Close;
        this.BODY_TEXT_BOLD = BODY_TEXT_VARIANTS.BOLD;
        this.BODY_TEXT_SIZE = BODY_TEXT_SIZES.M;
    },
};
</script>

<style lang="scss" scoped>
.fixed-action-modal {
    &:deep(.scrim) {
        @apply z-ui-2 fixed;
    }

    &:deep(.dialog) {
        @apply z-ui-4;
    }

    .text {
        @apply p-ui-6;
        @apply flex flex-col gap-y-ui-4;

        &:deep(a) {
            @apply underline;
        }
    }
}
</style>
