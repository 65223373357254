<template>
    <div>
        <pre>{{ propsToBind }}</pre>
    </div>
</template>

<script>
import CmsBaseComponent from '@modules/page-builder/components/CmsBaseComponent/CmsBaseComponent';

export default {
    name: 'CmsAppCounterV2',

    extends: CmsBaseComponent,

    props: {
        // eslint-disable-next-line vue/no-unused-properties
        promoStartDate: {
            type: String,
            default: '',
        },

        // eslint-disable-next-line vue/no-unused-properties
        promoStartCounterDate: {
            type: String,
            default: '',
        },

        // eslint-disable-next-line vue/no-unused-properties
        promoEndDate: {
            type: String,
            default: '',
            required: true,
        },
    },

    data() {
        return {
            // eslint-disable-next-line vue/no-unused-properties
            propsCustomFormConfig: {
                promoStartDate: {
                    hasDateTimePicker: true,
                },

                promoStartCounterDate: {
                    hasDateTimePicker: true,
                },

                promoEndDate: {
                    hasDateTimePicker: true,
                    required: true,
                },
            },

            // eslint-disable-next-line vue/no-unused-properties
            defaultConfig: {
                props: {
                    promoStartDate: '',
                    promoStartCounterDate: '',
                    promoEndDate: '',
                },
            },

            // eslint-disable-next-line vue/no-unused-properties
            options: {
                marginTop: false,
                staticClass: false,
            },
        };
    },
};
</script>
